<template>
  <div class="date-with-alert-container">
    <div v-bind:class="{ danger: danger, warning: warning }">
      {{ moment(date).format('DD/MM/YYYY') }}
    </div>
    <span :id="`date_alert_${id}`" v-if="danger" class="icon-box"><v-info-danger class="icon stroke"  /></span>
    <span :id="`date_alert_${id}`" v-if="warning" class="icon-box"><v-info-warning class="icon stroke"  /></span>
    <b-tooltip :target="`date_alert_${id}`" placement="bottom">
      {{ danger ? 'Conta vencida' : '' }}
      {{ warning ? 'Conta com vencimento próximo' : '' }}
    </b-tooltip>
  </div>
</template>
<script>
import moment from 'moment';
import InfoWarning from '@/assets/icons/info-circle-warning.svg'
import InfoDanger from '@/assets/icons/info-circle-danger.svg'
export default {
  components: {
    'v-info-warning': InfoWarning,
    'v-info-danger': InfoDanger,
  },
  props: {
    id: { type: String, require: true },
    date: { type: String, require: true },
    warning: Boolean,
    danger: Boolean,
  },
  methods: {
    moment,
  }
}
</script>
<style lang="scss" scoped>
.date-with-alert-container {
  display: inline-flex;
  align-items: center;
}
.icon {
  width: 18px;
  height: 18px;
  margin: -4px 0 0 5px;
}
.danger {
  font-weight: 600;
  color: var(--states-error);
}
.warning {
  font-weight: 600;
  color: var(--orange);
}
</style>
